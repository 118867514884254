<template>
  <RevCard class="space-y-24 p-24">
    <div class="space-y-16">
      <div class="grid grid-cols-1 gap-16 md:grid-cols-2 md:flex-row">
        <RevRadio
          id="option-keep-phone-number"
          :model-value="offerOption"
          name="option"
          value="KEEP_PHONE_NUMBER"
          variant="full"
          @update:model-value="handleOfferOptionUpdate($event as string)"
        >
          <template #label>
            {{ i18n(translations.keepPhoneNumberLabel) }}
          </template>

          <template #description>
            <PriceOption
              v-if="!isLoading && keepPhoneNumberPriceOption"
              :prices="keepPhoneNumberPriceOption.prices"
            />

            <div v-if="isLoading" class="space-y-4">
              <RevSkeleton height="16px" shape="rectangle" width="100%" />
              <RevSkeleton height="16px" shape="rectangle" width="100%" />
            </div>
          </template>
        </RevRadio>

        <RevRadio
          id="option-new-phone-number"
          :model-value="offerOption"
          name="option"
          value="NEW_PHONE_NUMBER"
          variant="full"
          @update:model-value="handleOfferOptionUpdate($event as string)"
        >
          <template #label>
            {{ i18n(translations.newPhoneNumberLabel) }}
          </template>

          <template #description>
            <div v-if="!isLoading && newPhoneNumberPriceOption">
              <PriceOption :prices="newPhoneNumberPriceOption.prices" />
            </div>

            <div v-if="isLoading" class="space-y-4">
              <RevSkeleton height="16px" shape="rectangle" width="100%" />
              <RevSkeleton height="16px" shape="rectangle" width="100%" />
            </div>
          </template>
        </RevRadio>
      </div>

      <p class="caption">
        <template v-if="!isLoading">
          {{ i18n(translations.simCardShippingInfo) }}
        </template>
        <template v-else>
          <RevSkeleton height="12px" shape="rectangle" width="75%" />
        </template>
      </p>
    </div>

    <div v-if="isLoading" class="space-y-16">
      <RevSkeleton height="16px" shape="rectangle" width="40%" />

      <div class="space-y-8">
        <RevSkeleton height="16px" shape="rectangle" width="100%" />
        <RevSkeleton height="16px" shape="rectangle" width="100%" />
        <RevSkeleton height="16px" shape="rectangle" width="100%" />
        <RevSkeleton height="16px" shape="rectangle" width="100%" />
      </div>
    </div>

    <div
      v-if="offerOption === 'KEEP_PHONE_NUMBER' && !isLoading"
      class="space-y-16"
    >
      <header>
        <div class="body-1-bold">
          {{ i18n(translations.phoneNumberTitle) }}
        </div>
        <div class="caption">
          {{ i18n(translations.phoneNumberSubtitle) }}
        </div>
      </header>

      <RevInputPhone
        id="phoneline-setup-phone-number"
        :clear-button-aria-label="i18n(translations.phoneNumberInputClear)"
        :default-country="MarketCountryCode.FR"
        description=""
        :error="errors.phoneNumber || apiErrors.phoneNumber"
        :label="i18n(translations.phoneNumberInputLabel)"
        :model-value="phoneNumber"
        :translate="i18n.country"
        @update:model-value="(value) => emit('update:phone-number', value)"
      />

      <RevInputText
        id="phoneline-setup-rio"
        :description="i18n(translations.RIODescription)"
        :error="errors.rioCode || apiErrors.rioCode"
        :label="i18n(translations.RIO)"
        :model-value="rioCode"
        @update:model-value="(value) => emit('update:rio-code', value)"
      />
      <RevInfoBlock
        :content="i18n(translations.RIOInfoBlockContent)"
        :icon="IconSim"
        :title="i18n(translations.RIOInfoBlockTitle)"
        variant="default"
      >
        <RevLink class="body-2-link" to="tel:3179">
          {{ i18n(translations.RIOInfoBlockLink) }}
        </RevLink>
      </RevInfoBlock>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type { PriceOption as PriceOptionType } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevInputPhone } from '@ds/components/InputPhone'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { RevRadio } from '@ds/components/Radio'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconSim } from '@ds/icons/IconSim'

import type { Values } from '../../PhonelineSetupForm.types'

import translations from './PhonelineCard.translations'
import PriceOption from './PriceOption/PriceOption.vue'

const i18n = useI18n()

const props = defineProps<{
  errors: Partial<Record<keyof Values, string>>
  apiErrors: Partial<Record<keyof Values, string>>
  offerOption: string
  rioCode: string
  phoneNumber: string
  priceOptions?: PriceOptionType[]
  isLoading: boolean
}>()

const emit = defineEmits([
  'update:offerOption',
  'update:phone-number',
  'update:rio-code',
])

const { trackClick } = useTracking()

const keepPhoneNumberPriceOption = computed(() =>
  props.priceOptions?.find(
    (priceOption) => priceOption.option === 'KEEP_PHONE_NUMBER',
  ),
)

const newPhoneNumberPriceOption = computed(() =>
  props.priceOptions?.find(
    (priceOption) => priceOption.option === 'NEW_PHONE_NUMBER',
  ),
)

function handleOfferOptionUpdate(offerOption: string) {
  trackClick({
    name: 'bouygues_offer_option',
    offer_option: offerOption,
  })

  emit('update:offerOption', offerOption)
}
</script>
